// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allies-jsx": () => import("./../../../src/pages/allies.jsx" /* webpackChunkName: "component---src-pages-allies-jsx" */),
  "component---src-pages-axis-jsx": () => import("./../../../src/pages/axis.jsx" /* webpackChunkName: "component---src-pages-axis-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-networth-jsx": () => import("./../../../src/pages/networth.jsx" /* webpackChunkName: "component---src-pages-networth-jsx" */),
  "component---src-pages-tools-jsx": () => import("./../../../src/pages/tools.jsx" /* webpackChunkName: "component---src-pages-tools-jsx" */)
}

